require('home2021/vendor/daterangepicker.min.js')

const NewBookingForm = {
  type: null,
  when: null,
  date: null,
  check_in_time: null,

  init: function(params = {}) {
    NewBookingForm.toggleScheduledFields($('#new_booking_form_select_for_time'));
    NewBookingForm.toggleScheduledFields($('#new_booking_form_mobile_select_for_time'));

    NewBookingForm.type = params['type']
    NewBookingForm.when = params['when']
    NewBookingForm.date = params['date']
    NewBookingForm.check_in_time = params['check_in_time']

    $('.i_fecha').daterangepicker({
      autoApply: true,
      singleDatePicker: true,
      showDropdowns: true,
      minYear: 1950,
      minDate: new Date($('.i_fecha').attr('data-min-date')),
      opens: "center",
      locale: {
        "format": 'YYYY-MM-DD',
        "applyLabel": "Guardar",
        "cancelLabel": "Cancelar",
        "fromLabel": "Desde",
        "toLabel": "Hasta",
        "customRangeLabel": "Personalizar",
        "daysOfWeek": [
          "Do",
          "Lu",
          "Ma",
          "Mi",
          "Ju",
          "Vi",
          "Sa"
        ],
        "monthNames": [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Setiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
        ],
        "firstDay": 1
      }
    });

    $('#new_booking_form_select_for_time, #new_booking_form_mobile_select_for_time').on('change', function() {
      NewBookingForm.toggleScheduledFields($(this));
      NewBookingForm.updateBookingType($(this));
      if ($(this).data('motel-scheduled-bookings')) {
        if ($('#quick-booking-button').attr('data-clicked') != 'true') {
          NewBookingForm.updateBookingForm(
            $(this).closest('.scheduled-fields-container').find('.room_select')
          )
        }
      }
    })

    $('#new_booking_form_mobile_select_check_in_time, #new_booking_form_select_check_in_time').on('change', function() {
      $(this).closest('.scheduled-fields-container').attr('data-check-in-time', $(this).val())
      NewBookingForm.updateBookingForm($(this).closest('.scheduled-fields-container').find('.room_select'))
    })

    $('#new_booking_form_datepicker, #date_mobile').on('change', function() {
      var containerDiv = $(this).closest('.scheduled-fields-container')
      containerDiv.attr('data-date', $(this).val())
      NewBookingForm.updateBookingForm(containerDiv.find('.room_select'))
    })

    $("#new_booking_form_mobile_select_for_room, #new_booking_form_select_for_room").on('change', function(event) {
      NewBookingForm.updateBookingForm($(this))
    })

    $('.request-reservation').on('click', function(event) {
      event.preventDefault()
      NewBookingForm.check_availability($(this))
    })

    $('.request-reservation-mobile').on('click', function(event) {
      event.preventDefault()
      NewBookingForm.check_availability_mobile($(this))
    })

    $('.quickValidation').on('change', function() {
      var hours = $(this).closest('div').find('.quick-booking-button')
                                        .attr('data-hours')
      setTimeout(function() {
        $('#new_booking_form_select_hours').val(hours)
        $('#new_booking_form_select_hours').trigger('change')
        NewBookingForm.check_availability($('.request-reservation'))
      }, 1000)
    })

    $('.quick-booking-button').on('click', function() {
      $('#quick-booking-button-container').removeClass('hidden-spinner')
      if ($(this).attr('data-clicked') != 'true') {
        $(this).attr('data-clicked', true)
        if ($('#new_booking_form_select_for_time').val() != 'now') {
          $('#new_booking_form_select_for_time').val('now')
          $('#new_booking_form_select_for_time').trigger('change')
        }
        $('#new_booking_form_select_for_room').val($(this).data('room'))
        $('#new_booking_form_select_for_room').trigger('change')
      }
    })
  },

  updateBookingForm: function(field) {
    var $target = field.find(':selected').data('href');
    if ($target == undefined) {
      var $target = field.closest('.scheduled-fields-container').data('href')
    }
    field.closest('.new-booking-form-container').removeClass('hidden-spinner')

    var date = field.closest('.scheduled-fields-container').attr('data-date')
    NewBookingForm.date = date

    var check_in_time = field.closest('.scheduled-fields-container').attr('data-check-in-time')
    NewBookingForm.check_in_time = check_in_time

    var when = field.closest('.scheduled-fields-container').attr('data-when')
    NewBookingForm.when = when

    $.ajax({
      url: $target,
      type: 'GET',
      dataType: 'script',
      data: {
        date: date,
        check_in_time: check_in_time,
        when: when,
        _mode: $('#_mode').val(),
        _referer: $('#_referer').val(),
        ref: $('#ref').val(),
      }
    });
  },

  updateBookingType: function(field) {
    var value = field.val() == 'another-day' ? 'scheduled' : 'express'
    field.closest('div').find('input').val(value)

    var $bookingFormFooterMessage = $("#new_booking_form_footer_message")
    var $bookingFormScheduledFooterMessage = $("#new_booking_form_scheduled_footer_message")

    if (value == 'express') {
      $bookingFormFooterMessage.show()
      $bookingFormScheduledFooterMessage.hide()
      NewBookingForm.type = 'express'
    }
    else if (value == 'scheduled') {
      $bookingFormFooterMessage.hide()
      $bookingFormScheduledFooterMessage.show()
      NewBookingForm.type = 'scheduled'
    }
  },

  toggleScheduledFields: function(field) {
    var fieldsContainer = field.closest('.scheduled-fields-container')
                               .find('.scheduled_fields')
    if (field.val() == 'now') {
      fieldsContainer.hide();
      fieldsContainer.find('input').attr('disabled', true)
      fieldsContainer.find('select').attr('disabled', true)
      field.closest('.scheduled-fields-container').attr('data-when', 'now')
      $('#new_booking_form_mobile_select_for_room').attr('data-when', 'now')
      $('#new_booking_form_select_for_room').attr('data-when', 'now')
      $("#new_booking_form_footer_message").show()
      $("#new_booking_form_scheduled_footer_message").hide()

    } else if (field.attr('data-motel-scheduled-bookings') == 'true') {
      fieldsContainer.show();
      fieldsContainer.find('input').attr('disabled', false)
      fieldsContainer.find('select').attr('disabled', false)
      field.closest('.scheduled-fields-container').attr('data-when', 'another-day')
      $('#new_booking_form_mobile_select_for_room').attr('data-when', 'another-day')
      $('#new_booking_form_select_for_room').attr('data-when', 'another-day')
      $("#new_booking_form_footer_message").hide()
      $("#new_booking_form_scheduled_footer_message").show()
    }
  },

  check_availability: function(button){
    var $scheduledFieldsContainer = $("#scheduled_fields_container_desktop")

    var hours_option = $scheduledFieldsContainer
                             .find('#new_booking_form_select_hours')
                             .children('option:selected')
    var motel = button.data('motel')
    var roomType = $scheduledFieldsContainer
                          .find('#new_booking_form_select_for_room').val()
    var date = $('#new_booking_form_datepicker').val()
    var hour = $scheduledFieldsContainer
                     .find('#new_booking_form_select_check_in_time').val()
    var hoursOfStay = $("#scheduled_fields_container_desktop")
                            .find('#new_booking_form_select_hours').val()
    var when = $scheduledFieldsContainer
                     .find('#new_booking_form_select_for_time').val()
    var wait = hours_option.data('wait')
    var checkout = hours_option.data('checkout')

    button.closest('.new-booking-form-container').removeClass('hidden-spinner')

    $.ajax({
      url: '/bookings/check_availability',
      type: 'POST',
      dataType: 'json',
      data: {
        motel: motel, room_type: roomType, date: date, hour: hour,
        hours_of_stay: hoursOfStay, when: when, wait: wait, checkout: checkout
      },
      success: function(result) {
        button.closest('.new-booking-form-container').addClass('hidden-spinner')
        $('#quick-booking-button-container').addClass('hidden-spinner')
        if (result && result.IsAvailable == true) {
          $(button).parents("form").trigger('submit')
        } else {
          Swal.fire({
            title: 'Lo Sentimos!!',
            text: "Esta habitación no está disponible para esta fecha",
            icon: 'warning',
            confirmButtonColor: '#7B2835',
            confirmButtonText: 'OK'
          })
        }
      }
    })
  },

  check_availability_mobile: function(button){
    var hours_option = button.closest('.scheduled-fields-container')
                             .find('#new_booking_modal_form_select_hours')
                             .children('option:selected')
    var motel = button.data('motel')
    var roomType = button.closest('.scheduled-fields-container')
                          .find('#new_booking_form_mobile_select_for_room').val()
    var date = $('#date_mobile').val()
    var hour = button.closest('.scheduled-fields-container')
                     .find('#new_booking_form_mobile_select_check_in_time').val()
    var hoursOfStay = button.closest('.scheduled-fields-container')
                            .find('#new_booking_modal_form_select_hours').val()
    var when = button.closest('.scheduled-fields-container')
                     .find('#new_booking_form_mobile_select_for_time').val()
    var wait = hours_option.data('wait')
    var checkout = hours_option.data('checkout')

    button.closest('.new-booking-form-container').removeClass('hidden-spinner')

    $.ajax({
      url: '/bookings/check_availability',
      type: 'POST',
      dataType: 'json',
      data: {
        motel: motel, room_type: roomType, date: date, hour: hour,
        hours_of_stay: hoursOfStay, when: when, wait: wait, checkout: checkout
      },
      success: function(result) {
        button.closest('.new-booking-form-container').addClass('hidden-spinner')
        if (result && result.IsAvailable == true) {
          $(button).parents("form").trigger('submit')
        } else {
          Swal.fire({
            title: 'Lo Sentimos!!',
            text: "Esta habitación no está disponible para esta fecha",
            icon: 'warning',
            confirmButtonColor: '#7B2835',
            confirmButtonText: 'OK'
          })
        }
      }
    })
  }
}

exports.NewBookingForm = NewBookingForm
