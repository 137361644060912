export default
{
  time_zone: 'America/Punta_Arenas',
  locale: 'es-CL',
  currency: 'CLP',
  production: ('true' == 'true'),
  // Orígenes remotos para recibir mensajes vía postMessage
  remote_origins: JSON.parse('["https://suite.motelnowapp.com","https://suite.motelnow.com.co"]'),
  // Listado de regiones o geografías soportadas
  regions: JSON.parse('[{"region_id":34,"region_name":"Magallanes","region_latitude":-52.2064316,"region_longitude":-72.1685001},{"region_id":38,"region_name":"Santiago","region_latitude":-33.4378439,"region_longitude":-70.6526683},{"region_id":40,"region_name":"Valparaíso","region_latitude":-33.045646,"region_longitude":-71.620361}]'),
}
